<template>

  <div class="pr_edit" v-if="data && data.role_name && edit_data">

    <div class="modal_user_content">

        <div class="settings_description">

          <form id="form_brand">

            <div class="nr">

                <div>
                  <div class="av" id="avataruserupload_target">
                    <img class="avatar_user avatar_item" :src="edit_data.logo" data-not-lazy>
                  </div>
                </div>

                <div class="nm">
                  <div class="field">

                    <div class="label">{{ $t('brand_manager_view_tab_brand_name') }}</div>
                    <n-input
                      v-model:value="edit_data.name" 
                      type="text" 
                      :value="edit_data.name" 
                      @input="validateName" 
                      :placeholder="$t('brand_manager_view_tab_brand_name')" 
                      :status="validationErrors.name ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.name"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.name ? 'error' : 'default'"
                     >
                      {{validationErrors.name ? validationErrors.name.message : ''}}
                    </n-alert>

                  </div>

                  <div style="clear:both">&nbsp;</div>

                  <div class="field fup">

                    <n-upload
                      ref="uploadLogoList"
                      :custom-request="submitAvatar"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="$t('brand_manager_view_tab_brand_logo')"
                        />
                    </n-upload>

                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo ? 'error' : 'default'"
             >
              {{validationErrors.logo ? validationErrors.logo.message : ''}}
            </n-alert>

            <div class="two_fld">

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_brand_country') }}</div>
                  <client-only>
          <n-select
                    v-model:value="edit_data.country_id"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_brand_country')"
                    :options="edit_data.items_country"
                    value-field="id"
                    label-field="name"
                  />
                </client-only>

              </div>

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_brand_product_types') }}</div>
                  <client-only>
          <n-select
                    v-model:value="multipleTypeSelections"
                    :status="validationErrors.types ? 'is-danger' : ''"
                    filterable
                    multiple
                    :placeholder="$t('brand_manager_view_tab_brand_product_types')"
                    :options="multipleTypeAvaliable"
                    value-field="id"
                    label-field="name"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.types"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.types ? 'error' : 'default'"
                   >
                    {{validationErrors.types ? validationErrors.types.message : ''}}
                  </n-alert>

              </div>

            </div>

            <div class="two_fld">

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_brand_email') }}</div>
                  <n-input 
                    v-model:value="edit_data.email" 
                    type="text" 
                    :value="edit_data.email" 
                    @input="validateEmail" 
                    :placeholder="$t('brand_manager_view_tab_brand_email')" 
                    :status="validationErrors.email ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.email"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.email ? 'error' : 'default'"
                   >
                    {{validationErrors.email ? validationErrors.email.message : ''}}
                  </n-alert>

              </div>

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_brand_slogan') }}</div>
                  <n-input 
                    v-model:value="edit_data.slogan" 
                    type="text" 
                    :value="edit_data.slogan" 
                    @input="validateSlogan" 
                    :placeholder="$t('brand_manager_view_tab_brand_slogan')" 
                    :status="validationErrors.slogan ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.slogan"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.slogan ? 'error' : 'default'"
                   >
                    {{validationErrors.slogan ? validationErrors.slogan.message : ''}}
                  </n-alert>

              </div>

            </div>

            <div class="two_fld">

                <div class="label">{{ $t('brand_manager_view_tab_brand_desc') }}</div>
                <n-input 
                  v-model:value="edit_data.info" 
                  type="textarea" 
                  :value="edit_data.info" 
                  @input="validateDesc"
                  :placeholder="$t('brand_manager_view_tab_brand_desc')" 
                  :status="validationErrors.info ? 'error' : ''"
                  :has-counter="hasCounter"
                  :rows="8"
                />

                <div class="field-words"><div class="words">{{ $t('brand_manager_view_description_word_count') }}: <span>{{ wordsCount }}</span></div></div>

                <n-alert v-if="validationErrors.info"
                  title="" 
                  :show-icon="false"
                  :type="validationErrors.info ? 'error' : 'default'"
                 >
                  {{validationErrors.info ? validationErrors.info.message : ''}}
                </n-alert>

            </div>

            <div class="two_fld">

              <div class="label">
                {{ $t('brand_manager_view_tab_brand_background') }} 

                <UiButton
                  @click="edit_data.background='remove';background='remove'"
                  name="Remove"
                  :type="'secondary link'"
                  />

              </div>
              <n-upload
                ref="uploadBackgroundList"
                v-model="background"  
                :custom-request="submitBackground"
                :show-file-list="false"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box" :style="(edit_data.background ? 'background-image: url(\''+edit_data.background+'\')' : '')">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

            </div>

            <div class="two_fld" v-if="false">

              <div class="label">
                {{ $t('brand_manager_view_tab_brand_cover_mobile') }} [800x350px] 

                <UiButton
                  @click="edit_data.cover_mobile='remove';cover_mobile='remove'"
                  name="Remove"
                  :type="'secondary link'"
                  />
              </div>
              <n-upload
                ref="uploadCoverMobileList"
                v-model="cover_mobile"  
                :custom-request="submitCoverMobile"
                :show-file-list="false"
                disabled="false"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box" :style="(edit_data.cover_mobile ? 'background-image: url(\''+edit_data.cover_mobile+'\')' : '')">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

            </div>

            <div class="two_fld" v-if="false">

              <div class="label">
                {{ $t('brand_manager_view_tab_brand_cover') }} [2700x700px]  

                <UiButton
                  @click="edit_data.cover='remove';cover='remove'"
                  name="Remove"
                  :type="'secondary link'"
                  />

              </div>
              <n-upload
                ref="uploadCoverList"
                v-model="cover"  
                :custom-request="submitCover"
                :show-file-list="false"
                disabled="false"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box" :style="(edit_data.cover ? 'background-image: url(\''+edit_data.cover+'\')' : '')">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

            </div>

            <div class="two_fld">

              <div class="label">{{ $t('brand_manager_view_tab_brand_photos') }}</div>
              <n-upload
                ref="uploadPhotoList"
                :custom-request="submitPhoto"
                :show-file-list="false"
                :disabled = "isUploading"
                multiple="true"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box_photos">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

             <div style="clear:both">&nbsp;</div>

             <div class="upload_photos" expanded>
                <draggable 
                  v-model="edit_data.items_photo" 
                  item-key="id" 
                  v-if="edit_data.items_photo" 
                  @start="drag=true"  
                  @end="drag=false" 
                  >
                    <template #item="{element}">
                      <div class="item">
                        <div class="item_table">
                          <div class="item_td">
                            <div class="delete" @click="removePhoto(element)">
                              <b-icon pack="fas" icon="circle" size="is-small"></b-icon>
                            </div>
                            <img :src="element.photo_small || element.temp">
                          </div>
                        </div>
                      </div>
                    </template>

                </draggable>
             </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.photos"
              title="" 
              :show-icon="false"
              :type="validationErrors.photos ? 'error' : 'default'"
             >
              {{validationErrors.photos ? validationErrors.photos.message : ''}}
            </n-alert>

            <div style="clear:both">&nbsp;</div>

            <div style="display: flex;"> 
              <UiButton
                @click="saveBrand()"
                :name="$t('universal_button_save')"
                :is-loading="isLoading"
                :type="'primary'"
                />
              <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
            </div>

          </form>

        </div>

    </div>

  </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger, useMessage, NMessageProvider } from 'naive-ui'
import draggable from 'vuedraggable'
import editFuncs from '/pages/brands-manager/func/editBrand.js'
import Utils from '/pages/brands-manager/func/utils.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()

const props = defineProps({
  data: {
    type: Object    
  },
  edit_data: {
    type: Object
  }
});

const avatar = ref('')
const background = ref('')
const cover_mobile = ref('')
const cover = ref('')
const multipleTypeSelections = ref([])
const multipleTypeAvaliable = ref([])
const hasCounter = ref(false)
const wordsCount = ref(0)
const validationErrors = ref({})
const isLoading = ref(false)
const drag = ref(false)
const edit_data = ref(props.edit_data)
const uploadLogoList = ref(null)
const uploadBackgroundList = ref(null)
const uploadCoverMobileList = ref(null)
const uploadCoverList = ref(null)
const uploadPhotoList = ref(null)
const isUploading = ref(false)

const editDataClone = Utils.makeClone(edit_data.value);

for(var k in edit_data.value.items_type_avaliable){
  if(edit_data.value.items_type_avaliable.hasOwnProperty(k)) {
     multipleTypeAvaliable.value.push({id:k, name:t(edit_data.value.items_type_avaliable[k])});
  }
}

for(var k in edit_data.value.items_type){
  for(var m in multipleTypeAvaliable.value){
    if(multipleTypeAvaliable.value[m].id == edit_data.value.items_type[k]) 
      multipleTypeSelections.value.push(multipleTypeAvaliable.value[m].id);
  }
}

const submitAvatar = (data) => {
  uploadAvatar(data.file.file);
}

const uploadAvatar = async (avatar_obj) => {
  if(avatar_obj.type != 'image/jpeg' && avatar_obj.type != 'image/png' && avatar_obj.type != 'image/heicd'  && avatar_obj.type != 'image/webp'){
    message.warning(t('brand_manager_view_tab_brand_logo_format_error'));
    uploadLogoList.value.clear();
    return false;
  }

  if(avatar_obj.size>1024*100){
    message.warning(t('brand_manager_view_tab_brand_logo_size_error'));
    uploadLogoList.value.clear();
    return false;
  }

  let formData = new FormData(); 
  formData.append('file', avatar_obj);
  
  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      avatar.value = res.path;
      edit_data.value.logo = res.full_path;
      uploadLogoList.value.clear();
    }else {
      uploadLogoList.value.clear();
    }
  }).catch(function (error) {
     uploadLogoList.value.clear();
  });
}

const submitBackground = (data) => {
  let background_obj = data.file.file;

  if(background_obj.type != 'image/jpeg' && background_obj.type != 'image/png' && background_obj.type != 'image/heicd'  && background_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_brand_logo_format_error'));
    uploadBackgroundList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(background_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '1000' || img.width != '2600') {
        message.error(t('brand_manager_view_tab_brand_background_size_error'));
        uploadBackgroundList.value.clear();
        return false;
      }
      
      uploadBackground(background_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadBackground = async (background_obj) => {
  let formData = new FormData(); 
  formData.append('file', background_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      background.value = res.path;
      edit_data.value.background = res.full_path;
      uploadBackgroundList.value.clear();
    }else {
      uploadBackgroundList.value.clear();
    }
  }).catch(function (error) {
     uploadBackgroundList.value.clear();
  });
}

const submitCoverMobile = (data) => {
  let cover_mobile_obj = data.file.file;

  if(cover_mobile_obj.type != 'image/jpeg' && cover_mobile_obj.type != 'image/png' && cover_mobile_obj.type != 'image/heicd'  && cover_mobile_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_brand_logo_format_error'));
    uploadCoverMobileList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(cover_mobile_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '350' || img.width != '800') {
        message.error(t('brand_manager_view_tab_brand_cover_mobile_size_error'));
        uploadCoverMobileList.value.clear();
        return false;
      }

      uploadCoverMobile(cover_mobile_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadCoverMobile = async (cover_mobile_obj) => {
  let formData = new FormData(); 
  formData.append('file', cover_mobile_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      cover_mobile.value = res.path;
      edit_data.value.cover_mobile = res.full_path;
      uploadCoverMobileList.value.clear();
    }else {
      uploadCoverMobileList.value.clear();
    }
  }).catch(function (error) {
     uploadCoverMobileList.value.clear();
  });
}

const submitCover = (data) => {
  let cover_obj = data.file.file;

  if(cover_obj.type != 'image/jpeg' && cover_obj.type != 'image/png' && cover_obj.type != 'image/heicd'  && cover_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_brand_logo_format_error'));
    uploadCoverList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(cover_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '700' || img.width != '2700') {
        message.error(t('brand_manager_view_tab_brand_cover_size_error'));
        uploadCoverList.value.clear();
        return false;
      }

      uploadCover(cover_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadCover = async (cover_obj) => {
  let formData = new FormData(); 
  formData.append('file', cover_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      cover.value = res.path;
      edit_data.value.cover = res.full_path;
      uploadCoverList.value.clear();
    }else {
      uploadCoverList.value.clear();
    }
  }).catch(function (error) {
     uploadCoverList.value.clear();
  });
}

const submitPhoto = (data) => {
  uploadPhoto(data.file.file);
}

const uploadPhoto = async (photo_obj) => {
  if(photo_obj.type != 'image/jpeg' && photo_obj.type != 'image/png' && photo_obj.type != 'image/heicd'  && photo_obj.type != 'image/webp'){
    message.warning(t('brand_manager_view_tab_products_photo_format_error'));
    uploadPhotoList.value.clear();
    return false;
  }

  isUploading.value = true;

  let formData = new FormData(); 
  formData.append('file', photo_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      edit_data.value.items_photo.push({
         id:0,
         sort_id: Math.floor(Math.random() * 10000000000000000000),
         path:res.path,
         temp:res.full_path
      });
      validatePhotos();
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }else {
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }
  }).catch(function (error) {
     uploadPhotoList.value.clear();
     isUploading.value = false;
  });
}

const removePhoto = (item) => {
  var index = edit_data.value.items_photo.indexOf(item);
  if (index !== -1) {
    edit_data.value.items_photo.splice(index, 1);
  }
  validatePhotos();
}

const validateLogo = () => {
  if(!editFuncs.validateLogo(edit_data.value.logo,avatar)){
    validationErrors.value['logo'] = { message: t('brand_manager_view_tab_brand_logo_format_error') };
  }else{
    delete validationErrors.value['logo'];
  }
}

const validateName = () => {
  var error = editFuncs.validateName(edit_data.value.name);
  if(error == 1){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_length_error') };
  }else if(error == 2){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_format_error') };
  }else{
    delete validationErrors.value['name'];
  }
}

const validateTypes = () => {
  if(!editFuncs.validateTypes(multipleTypeSelections.value)){
    validationErrors.value['types'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['types'];
  }
}

const validateSlogan = () => {
  if(!editFuncs.validateSlogan(edit_data.value.slogan)){
    validationErrors.value['slogan'] = { message: t('brand_manager_view_tab_brand_slogan_length_error') };
  }else{
    delete validationErrors.value['slogan'];
  }
}

const validateEmail = () => {
  if(!editFuncs.validateEmail(edit_data.value.email)){
    validationErrors.value['email'] = { message: t('brand_manager_view_tab_brand_email_format_error') };
  }else{
    delete validationErrors.value['email'];
  }
}

const wordCounter = () => {
  wordsCount.value = editFuncs.wordCounter(edit_data.value.info);
}

const validateDesc = () => {
  wordCounter();
  if(!editFuncs.validateDesc(edit_data.value.info,editDataClone.info)){
    validationErrors.value['info'] = { message: t('brand_manager_view_tab_brand_desc_length_error') };
  }else{
    delete validationErrors.value['info'];
  }
}

const validatePhotos = () => {
  if(!editFuncs.validatePhotos(edit_data.value.items_photo)){
    validationErrors.value['photos'] = { message: t('brand_manager_view_tab_products_photos_count_error') };
  }else{
    delete validationErrors.value['photos'];
  }
}


wordCounter();

const saveBrand = () => {

  validateLogo();
  validateName();
  validateSlogan();
  validateEmail();
  validateTypes();
  validateDesc();
  validatePhotos();

  if(Object.keys(validationErrors.value).length>0){
    console.log(validationErrors.value);
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }

  var params = {};
  params.name = edit_data.value.name;
  params.email = edit_data.value.email;
  params.info = edit_data.value.info;
  params.country_id = edit_data.value.country_id;
  params.avatar = avatar.value;
  params.background = background.value ? background.value : '';
  params.cover_mobile = cover_mobile.value ? cover_mobile.value : '';
  params.cover = cover.value ? cover.value : '';
  params.slogan = edit_data.value.slogan;

  params.items_type = [];
  for(var t2 in multipleTypeSelections.value){
    params.items_type.push({id:multipleTypeSelections.value[t2]});
  }

  // photos 
  var _array = [];
  for(var t2 in editDataClone.items_photo){
    _array.push(editDataClone.items_photo[t2]);
  }

  params.items_photo = [];
  params.items_remove_photo = [];

  if(JSON.stringify(edit_data.value.items_photo) != JSON.stringify(_array)){
    for(var t2 in edit_data.value.items_photo){
      params.items_photo.push(
      {
        id:edit_data.value.items_photo[t2].id,
        sort_id: Math.floor(Math.random() * 10000000000000000000),
        temp:edit_data.value.items_photo[t2].path
      }
      );
    }

    for(var t2 in editDataClone.items_photo){
      var hasPhoto = false;
      for(var t3 in edit_data.value.items_photo){
        if(editDataClone.items_photo[t2].id == edit_data.value.items_photo[t3].id) 
          hasPhoto = true;
      }
      if(!hasPhoto) params.items_remove_photo.push(
      {
        id:editDataClone.items_photo[t2].id,
        temp:editDataClone.items_photo[t2].path
      }
      );
    }
  }

  saveBrandSend(params);
  //console.log(params);

}

/*const saveBrandSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveBrand({'brandseed': params}).then(res => {
    if(res && res == 'success'){
      message.success(t('brand_manager_view_tab_brand_save_success'));
      window.setTimeout('location.reload()', 1000);
      isLoading.value = false;
    }
  })
}*/

const saveBrandSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveBrand({'brandseed': params}).then(res => {
    message.success(t('brand_manager_view_tab_brand_save_success'));
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger } from 'naive-ui'
import draggable from 'vuedraggable'
import editFuncs from '/pages/brands-manager/func/editBrand.js'
import Utils from '/pages/brands-manager/func/utils.js'

export default { 
  props: {
    data: {
      type: Object
    },
    edit_data: {
      type: Object
    }
  },
  components: {
      draggable,
      NButton,
      NSelect,
      NInput,
      NAlert,
      NUpload,
      NUploadDragger
  },
  data() {
    return {
      avatar: '',
      background: '',
      cover_mobile: '',
      cover: '',
      multipleTypeSelections: [],
      multipleTypeAvaliable: [],
      hasCounter: false,
      wordsCount: 0,
      editDataClone: {},
      validationErrors: {},
      hasError: 0,
      isLoading: false,
      drag: false,
    }
  },

  created() {

    for(var k in this.edit_data.items_type_avaliable){
      if(this.edit_data.items_type_avaliable.hasOwnProperty(k)) {
         this.multipleTypeAvaliable.push({id:k, name:this.$t(this.edit_data.items_type_avaliable[k])});
      }
    }
    for(var k in this.edit_data.items_type){
      for(var t in this.multipleTypeAvaliable){
        if(this.multipleTypeAvaliable[t].id == this.edit_data.items_type[k]) 
          this.multipleTypeSelections.push(this.multipleTypeAvaliable[t].id);
      }
    }
    this.wordCounter();
    //this.editDataClone = Object.assign({}, this.edit_data);
    this.editDataClone = this.makeClone(this.edit_data);

  },

  methods: {
    submitAvatar: editFuncs.submitAvatar,
    uploadAvatar: editFuncs.uploadAvatar,
    submitBackground: editFuncs.submitBackground,
    uploadBackground: editFuncs.uploadBackground,
    submitCoverMobile: editFuncs.submitCoverMobile,
    uploadCoverMobile: editFuncs.uploadCoverMobile,
    submitCover: editFuncs.submitCover,
    uploadCover: editFuncs.uploadCover,
    submitPhoto: editFuncs.submitPhoto,
    uploadPhoto: editFuncs.uploadPhoto,
    removePhoto: editFuncs.removePhoto,
    wordCounter: editFuncs.wordCounter,
    strip_tags: editFuncs.strip_tags,
    showNotification: Utils.showNotification,
    showErrorNotification: Utils.showErrorNotification,
    showErrorSubNotification: Utils.showErrorSubNotification,
    validateLogo: editFuncs.validateLogo,
    validateName: editFuncs.validateName,
    validateSlogan: editFuncs.validateSlogan,
    validateEmail: editFuncs.validateEmail,
    validateTypes: editFuncs.validateTypes,
    validateDesc: editFuncs.validateDesc,
    validatePhotos: editFuncs.validatePhotos,
    validate: editFuncs.validate,
    makeClone: Utils.makeClone,
    saveBrand(){

      this.validate();
      if(this.hasError){
        this.showErrorNotification('brand_manager_view_generic_error');
        //const { $popup } = useNuxtApp();
        //this.$popup.error('fghg');
        return false;
      }

      var params = {};
      params.name = this.edit_data.name;
      params.email = this.edit_data.email;
      params.info = this.edit_data.info;
      params.country_id = this.edit_data.country_id;
      params.avatar = this.avatar;
      params.background = this.background ? this.background : '';
      params.cover_mobile = this.cover_mobile ? this.cover_mobile : '';
      params.cover = this.cover ? this.cover : '';
      params.slogan = this.edit_data.slogan;

      params.items_type = [];
      for(var t in this.multipleTypeSelections){
        params.items_type.push({id:this.multipleTypeSelections[t]});
      }

      // photos 
      var _array = [];
      for(var t in this.editDataClone.items_photo){
        _array.push(this.editDataClone.items_photo[t]);
      }

      params.items_photo = [];
      params.items_remove_photo = [];

      if(JSON.stringify(this.edit_data.items_photo) != JSON.stringify(_array)){
        for(var t in this.edit_data.items_photo){
          params.items_photo.push(
          {
            id:this.edit_data.items_photo[t].id,
            temp:this.edit_data.items_photo[t].path
          }
          );
        }

        for(var t in this.editDataClone.items_photo){
          var hasPhoto = false;
          for(var t2 in this.edit_data.items_photo){
            if(this.editDataClone.items_photo[t].id == this.edit_data.items_photo[t2].id) 
              hasPhoto = true;
          }
          if(!hasPhoto) params.items_remove_photo.push(
          {
            id:this.editDataClone.items_photo[t].id,
            temp:this.editDataClone.items_photo[t].path
          }
          );
        }
      }

      this.saveBrandSend(params);

    },

    async saveBrandSend(params){

      this.isLoading = true;
      let res = await this.$api.postB2BSaveBrand({
        'brandseed': params
      });
      if(res && res == 'success'){
        this.showNotification('brand_manager_view_tab_brand_save_success');
        window.setTimeout('location.reload()', 1000);
        this.isLoading = false;
      }

    }
  }
}
*/
</script>

<style scoped>

</style>

<style>

/* EDIT BRAND */

.two_fld{
  
}

.pr_edit .two_fld>.group50 {
  width: 50%;
}

.pr_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.pr_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.pr_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.pr_edit .nr {
  display: flex;
}

.pr_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.pr_edit .nr .av img {
  width: 100%;
}

.pr_edit .nr .nm {
  width: 250px;
}

.pr_edit .nr .fup {
  position: relative;
}
.pr_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.pr_edit .two_fld>.field {
  width: 100%;
}

.pr_edit .two_fld .select,.pr_edit .two_fld .select select{
  width: 100%!important;
}

.pr_edit .two_fld .button {
  width: 100%!important;
}

.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.field-words{
  width: 100%;
  text-align: right;
}

/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box.background{
  min-height: 200px;
}

.no-padding{
  padding: 0px;
}

.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}

.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos{
  width: 100%;
}

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}



@media (max-width: 768px) {
  .pr_edit .two_fld>.field{
    width:100%;
  }
}
</style>